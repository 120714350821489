import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../components/page-layout";
import { useAuth0 } from "@auth0/auth0-react";
import apiService from '../services/apiService'; 
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableItem from '../components/draggable-item';
import DropZone from '../components/drop-zone';
import { CharacterEditScreen } from "../components/character-edit-screen";
import ImageBox from '../components/image-box'; // Import the ImageBox component

export const MainPage = () => {
  const { user, isLoading } = useAuth0();
  const [currentEntities, setCurrentEntities] = useState([
    {id:1, name: "entity1", description: "Description 1", position: {x: 0, y: 0}}, 
    {id:2, name: "entity2", description: "Description 2", position: {x: 0, y: 0}}, 
    {id:3, name: "entity3", description: "Description 3", position: {x: 0, y: 0}}
  ]);
  const [showInputScreen, setShowInputScreen] = useState(false);
  const [newEntity, setNewEntity] = useState({name: '', description: '', role: '', personality: '', motivation: '', flaw: '', intrigue: '', physicalDescription: ''});
  const [editStates, setEditStates] = useState({});
  const imageUrl = 'https://s.mj.run/J0OhkV7R3OU'; // Define the image URL

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  const toggleInputScreen = () => {
    setShowInputScreen(!showInputScreen);
  };

  const handleAddEntity = () => {
    const newEntry = {
      id: currentEntities.length + 1,
      ...newEntity
    };
    setCurrentEntities([...currentEntities, newEntry]);
    setNewEntity({name: '', description: '', role: '', personality: '', motivation: '', flaw: '', intrigue: '', physicalDescription: ''}); 
    toggleInputScreen(); 
  };

  const handleEditChange = (id, field, value) => {
    setCurrentEntities(currentEntities.map(entity => 
      entity.id === id ? { ...entity, [field]: value } : entity
    ));
  };

  const toggleEditState = (id) => {
    setEditStates({
      ...editStates,
      [id]: !editStates[id]
    });
  };

  return (
    <PageLayout>
      <div className="gma-wrapper">
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="saved-container">
              <h2>Characters:</h2>
              {currentEntities.map((entity, index) => (
                <div key={index} className="card">
                  <div className="d-flex align-items-center">
                    {editStates[entity.id] ? (
                      <CharacterEditScreen 
                        newEntity={entity} 
                        setNewEntity={(updatedEntity) => handleEditChange(entity.id, 'name', updatedEntity.name)} 
                        handleAddEntity={() => toggleEditState(entity.id)} 
                        toggleInputScreen={() => toggleEditState(entity.id)} 
                      />
                    ) : (
                      <div>
                    <ImageBox imageUrl={imageUrl} />
                      <h3 className="">{entity.name}</h3>
                      <p className="description">{entity.description}</p>
                      </div>

                    )}
                  </div>
                  <button onClick={() => toggleEditState(entity.id)}>
                    {editStates[entity.id] ? 'Save' : 'Edit'}
                  </button>
                </div>
              ))}
            </div>
            <button className="add-entity-button" onClick={toggleInputScreen}>+</button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};