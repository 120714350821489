// src/screens/CharacterEditScreen.js
import React, { useState } from "react";
import ImageBox from "../components/image-box";

const subcategories = [
  { name: "Nobles & Clergy", description: "Individuals from noble families or religious figures." },
  { name: "Merchants & Artisans", description: "People involved in trade or skilled crafts." },
  // Add more subcategories as needed
];

const roles = [
  { name: "Leader", description: "A person who leads or commands a group." },
  { name: "Advisor", description: "A person who provides advice or guidance." },
  // Add more roles as needed
];

const personalities = [
  { name: "Brave", description: "Ready to face and endure danger or pain." },
  { name: "Cautious", description: "Careful to avoid potential problems or dangers." },
  // Add more personalities as needed
];

const motivations = [
  { name: "Power", description: "Desire to control or influence others." },
  { name: "Wealth", description: "Desire to accumulate money or valuable possessions." },
  // Add more motivations as needed
];

const flaws = [
  { name: "Arrogant", description: "Having an exaggerated sense of one's own importance or abilities." },
  { name: "Greedy", description: "Having an intense and selfish desire for something, especially wealth or power." },
  // Add more flaws as needed
];

const intrigues = [
  { name: "Secretive", description: "Inclined to conceal feelings and intentions or not to disclose information." },
  { name: "Manipulative", description: "Exercising unscrupulous control or influence over a person or situation." },
  // Add more intrigues as needed
];

export const CharacterEditScreen = ({ newEntity, setNewEntity, handleAddEntity, toggleInputScreen }) => {
  const [selectedCharacter, setSelectedCharacter] = useState({ name: "Default Name", description: "Default Description" });

  const handleCharacterSelection = (name, description) => {
    setSelectedCharacter({ name, description });
  };

  const renderDropdown = (options, label) => (
    <div className="dropdown-container">
      <label>{label}</label>
      <select onChange={(e) => {
        const selectedOption = options.find(option => option.name === e.target.value);
        handleCharacterSelection(selectedOption.name, selectedOption.description);
      }}>
        {options.map(option => (
          <option key={option.name} value={option.name}>{option.name}</option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="edit-screen-container" style={{ }}>
      <h2 className="edit-screen-header">Edit Character</h2>
      <div className="character-portrait">
        <img src="https://s.mj.run/J0OhkV7R3OU" alt="Character Portrait" className="portrait-image" />
        <div className="character-info">
          <p>Name: {selectedCharacter.name}</p>
          <p>Description: {selectedCharacter.description}</p>
        </div>
      </div>
      <form className="">
        {renderDropdown(subcategories, "Subcategory")}
        {renderDropdown(roles, "Role")}
        {renderDropdown(personalities, "Personality")}
        {renderDropdown(motivations, "Motivation")}
        {renderDropdown(flaws, "Flaw")}
        {renderDropdown(intrigues, "Intrigue")}
        <div className="button-group">
          <button type="button" onClick={handleAddEntity}>Add</button>
          <button type="button" onClick={toggleInputScreen}>Cancel</button>
        </div>
      </form>
    </div>
  );
};
