import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate instead of useHistory
import { PageLayout } from "../components/page-layout";
import { useAuth0 } from "@auth0/auth0-react";
import { RPGTextBox } from './inputs/rpg-textbox'; // Import the RPGTextBox component

export const NewPage = ({}) => {
  const { user, isLoading } = useAuth0();
  const navigate = useNavigate(); // useNavigate instead of useHistory

  const handleNewClick = () => {
    navigate("/new"); // navigate instead of history.push
  };

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  console.log(user);

  return (
    <PageLayout>
      <div className="container-fluid gma-wrapper">
         new
      </div>
      <RPGTextBox textList={["Hello, Game Master.", "[Tutorial here]"]} route={"/main"} />
    </PageLayout>
  );
};