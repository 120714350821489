// src/components/ImageBox.js
import React from 'react';

const ImageBox = ({ imageUrl, onClick, borderRadius = '100px' }) => {
    const imageBoxStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100px', // Adjust width as needed
        height: '100px', // Adjust height as needed
        border: '1px solid #ccc',
        borderRadius: borderRadius,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer'
    };

    return <div style={imageBoxStyle} onClick={onClick}></div>;
};

export default ImageBox;